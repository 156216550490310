import './App.css';
import Header from './Components/Header';

function App() {

  return (
    <div className="App justify-between">
      <Header />
    </div>
  );
}



export default App;
