import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import { premiumData } from "../cms/premium-banner";
import React from 'react';

export default class Premium extends React.Component {
    render() { 

    return (
        <section id="premium-card" className='bg-pink-200 dark:bg-gray-800'>
            <div id="upcoming-banner" tabIndex="-1" className="flex z-50 justify-center py-3 px-4 w-full bg-gray-50 border border-gray-200 dark:border-gray-600 lg:py-4 dark:bg-gray-700 mb-4">
                <div className="items-center md:flex">
                    <p className="d34f text-3xl font-bold text-gray-900 md:my-0 dark:text-white">
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 hidden md:inline">New</span>
                        Premium NFTs straight from Collector
                    </p>
                </div>
            </div>
            <div className="flex flex-row flex-wrap">
                {this.props.hm === '3' ?
                
                premiumData.slice(0, 3).map((e, idx) => 
                
                   
                <div key={idx} className="max-w-md mx-auto mb-4 rounded-md shadow-xl bg-blue-300 dark:bg-gray-800 dark:border-gray-700">
                    <InnerImageZoom  fullscreenOnMobile={false} className="p-3 rounded-t-lg" src={e.image[0]} />
                    <div className="px-5 pb-5">
                        <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">{e.title}</h5>
                        <div className="flex justify-between items-center">
                            <span className="text-3xl font-bold text-gray-900 dark:text-white">{e.price}</span>
                            {/* <a href="" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Buy now</a> */}
                        </div>
                    </div>
                </div>
                
              
                ): premiumData.map((e, idx) =>   
                <div key={idx} className="max-w-md mx-auto mb-4 rounded-md shadow-xl bg-blue-300 dark:bg-gray-800 dark:border-gray-700">
                    <InnerImageZoom  fullscreenOnMobile={false} className="p-3 rounded-t-lg" src={e.image[0]} />
                    <div className="px-5 pb-5">
                        <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">{e.title}</h5>
                        <div className="flex justify-between items-center">
                            <span className="text-3xl font-bold text-gray-900 dark:text-white">{e.price}</span>
                            {/* <a href="" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Buy now</a> */}
                        </div>
                    </div>
                </div>
                
              
                )
                } 
            </div>
        </section>
    )

}
}