import CarouselBox from './CarouselBox';

function UpcomingAlbums() {
    return (
        <div className="">
            <div id="upcoming-albums" tabIndex="-1" className="flex z-50 justify-center py-3 px-4 w-full bg-gray-50 border border-b border-gray-200 dark:border-gray-600 lg:py-4 dark:bg-gray-700 my-4">
                <div className="items-center md:flex">
                    <p className="d34f text-3xl font-bold text-gray-900 md:my-0 dark:text-white">
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 hidden md:inline">New</span>
                        Upcoming Albums
                    </p>
                </div>
            </div>
            <CarouselBox />
            
        </div>
        

    )
}

export default UpcomingAlbums;