import Feeter from "./Footer";
import Premium from './Premium';
import UpcomingAlbums from './UpcomingAlbums';
import { Link } from "react-router-dom";
import UpcomingPosters from './UpcomingPosters';
function Contact() {
    return (
        <div className="relative  flex min-h-100 flex-col justify-center overflow-hidden bg-pink-200  py-2 sm:py-12 dark:bg-gray-800">
            <span className="block p-6 mx-auto bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900  text-center dark:text-white">Need help in listing your Physical NFT collectibles ?</h5>
                <p className="text-gray-700 dark:text-gray-400 font-bold text-center">Contact: locacollectibles@gmail.com</p>
                <p className="font-bold text-yellow-400 dark:text-gray-400">Process is simple, as long as you are ready to let go of your collectibles :)</p>
            </span>
        </div>

    )
}
function MktBanner() {
    return (

        <div className="relative flex flex-col justify-center overflow-hidden py-2 sm:py-12 dark:bg-gray-700">
            <div className="mx-auto max-w-4xl bg-[#092540] p-8 text-center">
                <p className="d34f mb-6 text-5xl font-bold text-gray-300 dark:text-white">&ldquo;All that is <i className="text-yellow-400">Gold</i> does not glitter,</p>
                <p className="d34f mb-4 text-5xl font-bold text-gray-300 dark:text-white">Not all those who wander are lost.&rdquo;</p>
                <p className="mt-3 text-gray-500 sm:text-lg dark:text-gray-400">J.R.R. Tolkien, The Fellowship of the Ring.</p>

            </div>
        </div>




    )


}

function Home() {
    return (
        <div className="bg-pink-200 dark:bg-gray-700">
            <MktBanner />
            <Premium hm="3" />
            <Link className="flex w-1/3 mx-auto justify-center bg-yellow-400 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" to="/featured">See all</Link>
            <UpcomingAlbums />
            <Link className="flex w-1/3 mx-auto my-3 justify-center bg-yellow-400 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" to="/albums">Checkout all Albums</Link>
            {/* <UpcomingPosters />
            <Link className="flex w-1/3 mx-auto my-3 justify-center bg-yellow-400 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" to="/posters">Checkout all Posters</Link> */}
            <Contact />
            <Feeter />
        </div>
    )
}

export default Home;


