
import { Carousel } from 'flowbite-react';
import img1 from '../media/albums/Cocktail-1.jpg'
import img2 from '../media/albums/J-Geils-1.jpg'
import img3 from '../media/albums/Steal-Election.jpg'
import img4 from '../media/albums/Whorehouse-1.jpg'
import img5 from '../media/albums/La-Mancha-1.jpg'

function CarouselBox() {

    return (
        <div className="max-w-3xl h-56 sm:h-64 xl:h-80 2xl:h-96 mx-auto">
        <Carousel slideInterval={2500}>
          <img
            src={img1}
            className=""
            alt="Album"
          />
          <img
            src={img2}
            alt="Album 2"
          />
          <img
            src={img3}
            alt="Album 3"
          />
          <img
            src={img4}
            alt="Album 4"
          />
          <img
            src={img5}
            alt="Album  5"
          />
        </Carousel>
      </div>
    );
}

export default CarouselBox;