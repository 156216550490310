

    export const premiumData  = [    
    {
            
        "title": "Michael Jackson Hat",
        "type": "Collection",
        "signed" : "yes",
        "image": [
            "/media/Michael-Jackson-Hat.jpg",
            "/media/Michael-Jackson-Hat.jpg"
        ],
        "price": "333 Eth"
    },
    {
        
        "title": "Rolling Stones Signed Guitar 5 Members",
        "type": "Collection",
        "signed" : "yes",
        "image": [
            "/media/rolling.jpeg",
            "/media/rolling.jpeg"
        ],
        "price": "100 Eth"
    },
    {
        
        "title": "Elvis Presley Harum Scarum",
        "type": "Album",
        "signed" : "yes",
        "image": [
            "/media/IMG_6269.JPEG",
            "/media/IMG_6269.JPEG"
        ],
        "price": "15 Eth"
    },
    {
        
        "title": "Jimi Hendrix PostCard",
        "type": "Album",
        "signed" : "yes",
        "image": [
            "/media/IMG_6284.JPEG",
            "/media/IMG_6284.JPEG"
        ],
        "price": "20 Eth"
    },
    {
            
        "title": "Stephen King poster and Baseball Signed",
        "type": "Collection",
        "signed" : "yes",
        "image": [
            "/media/IMG_6298.JPEG",
            "/media/IMG_6298.JPEG"
        ],
        "price": "111 Eth"
    },
    {
        
        "title": "Muhammad Ali vs Sonny Liston Signed Poster",
        "type": "Collection",
        "signed" : "yes",
        "image": [
            "/media/IMG_6289.JPEG",
            "/media/IMG_6289.JPEG"
        ],
        "price": " 25 Eth"
    },
    {
        
        "title": "Cher Shoes Rare - worn and signed",
        "type": "Collection",
        "signed" : "yes",
        "image": [
            "/media/IMG_0281.JPG",
            "/media/IMG_0281.JPG"
        ],
        "price": "11 Eth"
    },
    {
            
        "title": "Michael Jackson Platinum Edition ",
        "type": "Album",
        "signed" : "yes",
        "image": [
            "/media/IMG_6295.JPEG",
            "/media/IMG_6295.JPEG"
        ],
        "price": "50 Eth"
    },
];


