    export const premiumData  = [           
        {
            
            "title": "Johnny Cash - Mean as Hell",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/cash-mean.jpeg",
                "/media/cash-mean.jpeg"
            ],
            "price": "2"
        },
        {
            
            "title": "Johnny Cash - Ride This Train",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/cash-ride.jpeg",
                "/media/cash-ride.jpeg"
            ],
            "price": "2"
        },
        {
            
            "title": "Holly Near and Ronnie Gilbert - LIFELINE",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_7810.JPEG",
                "/media/IMG_7810.JPEG"
            ],
            "price": "3"
        },
        {  
            "title": "Linda Hopkins",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_7813.JPEG",
                "/media/IMG_7813.JPEG"
            ],
            "price": "3"
        },
        {
            
            "title": "Cher Album",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_6029.JPEG",
                "/media/IMG_6029.JPEG"
            ],
            "price": "3"
        },
        {  
            "title": "Topol",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_6039.JPEG",
                "/media/IMG_6039.JPEG"
            ],
            "price": "3"
        },
        {  
            "title": "Cocktail Movie Soundtrack",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_6013.JPEG",
                "/media/IMG_6013.JPEG"
            ],
            "price": "3"
        },
        {
            
            "title": "Golden Age of Comedy ",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/golden-age-comedy.jpeg",
                "/media/golden-age-comedy.jpeg"
            ],
            "price": "3"
        },
        {
            
            "title": "Joan Rivers 1983",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/joan.jpeg",
                "/media/joan.jpeg"
            ],
            "price": "3"
        },
       
        {
            
            "title": "Barbarra Cook - Its Better with a Band",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_7220.JPEG",
                "/media/IMG_7220.JPEG",
            ],
            "price": "3"
        },
        {
            
            "title": "Johhny Crawford - His Greatest Hits",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_7825.JPEG",
                "/media/IMG_7825.JPEG"
            ],
            "price": "3"
        },
        {
            
            "title": "Pat Boone - White Christmas",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_6037.JPEG",
                "/media/IMG_6037.JPEG"
            ],
            "price": "3"
        },
        {
            
            "title": "Jennifer Holliday - Feel My Soul",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_7819.JPEG",
                "/media/IMG_7819.JPEG"
            ],
            "price": "3"
        },
        {
            
            "title": "Man of LaMancha - Original Cast Recording ",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_6027.JPEG",
                "/media/IMG_6027.JPEG"
            ],
            "price": "3"
        },
        {
            
            "title": "Ben Vereen ",
            "type": "Album",
            "signed" : "yes",
            "image": [
                "/media/IMG_7823.JPEG",
                "/media/IMG_7823.JPEG"
            ],
            "price": "3"
        },
        
    ];
    
    
    